:root {
  --black-color: #272727;
  --grey-color: #6b6b6b;
  --light-grey-color: #9e9fa7;
  --orange-color: #f07d00;
  --shadow-1: 13px 13px 20px #cbced1;
  --shadow-2: -9px -9px 20px #fff;
  --background-card-color: #ecf0f3;
  --primary-color: #313848;
}

p {
  margin-bottom: 0;
}

body {
  background: radial-gradient(
    circle,
    rgba(221, 221, 221, 1) 0%,
    rgba(238, 238, 238, 1) 100%
  );
}

/* Color */

.text-grey {
  color: #6b6b6b;
}

.text-light-grey {
  color: #9e9fa7;
}

.text-orange {
  color: #f07d00;
}

.text-black {
  color: #272727;
}

.text-green {
  color: #1fc264;
}

.text-red {
  color: #ff4c41;
}

.text-blue {
  color: #51a6f5;
}

.white-color {
  color: #fff;
}

.text-light-grey {
  color: var(--light-grey-color);
}

.primary-color {
  color: var(--primary-color);
}

/* Font Sizes */

.large-text {
  font-size: 20px;
}

.mid-large-text {
  font-size: 18px;
}

.title-text {
  font-size: 2em;
  font-weight: 600;
}

.small-text {
  font-size: 14px;
}

.medium-text {
  font-size: 16px;
}

.very-small-text {
  font-size: 12px;
}

/* Flexing Gap */

.flex-small-gap {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.flex-gap {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-at-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.flex-small-gap-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.flex-gap-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flex-mid-gap-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.medium-margin-top {
  margin-top: 1.5rem;
}

.small-margin {
  margin: 1rem;
}

.small-margin-top {
  margin-top: 1rem;
}

.small-padding {
  padding: 1rem;
}

.padding-rl {
  padding: 0 1rem;
}

.medium-padding {
  padding: 1.5rem;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.text-padding-left {
  padding-left: 1rem;
}

.filter-box-inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.filter-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.774);
}

.filter-button--active {
  color: #fff;
  background-color: #1fc264;
  border: #1fc264 1px solid;
  box-shadow: none;
}

/* Misc */

.link {
  transition: 0.3s;
}

.link:hover {
  transform: scale(1.05);
  color: #f07d00;
}

.hidden {
  display: none;
}

.ant-input {
  color: grey;
  font-weight: 600;
}

.ant-picker {
  display: flex;
}

.ant-picker-input > input {
  color: grey !important;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.ant-select {
  color: grey;
  font-weight: 600;
}

.ant-select-selection-item {
  color: grey;
  font-weight: 600 !important;
}

.ant-table-thead > tr > th {
  font-weight: 700;
  color: #272727;
}

.ant-table table {
  font-size: 16px;
  font-weight: 600;
  color: #6b6b6b;
}

.ant-form-item {
  margin-bottom: 0px;
}

.pointer {
  cursor: pointer;
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
}

.grid-2-column {
  grid-column: 1/3;
}

.gap{
  gap: 2rem;
}
